import http from "../axios/http"

//生活场景列表
export let getList = (params) => {
    return http.get("/speech/classification_list", { params })
}
//生活场景添加
export let getAdd = (params) => {
    return http.post("/speech/classification_add", params)
}
//生活场景编辑详情
export let getEdit = (params) => {
    return http.get("/speech/classification_edit", { params })
}
//生活场景编辑详情保存
export let getEditSave = (params) => {
    return http.post("/speech/classification_edit", params)
}
//生活场景删除
export let getDelete = (params) => {
    return http.post("/speech/classification_del", params)
}
