<template>
  <div class="LiveScene page">
    <div class="topbottom">
      <a-button type="primary" @click="addScene">生活添加场景</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="classify_id"
      :pagination="{
        current: page,
        total: total,
      }"
    >
      <template #menustatu="{ record }">
        <a-switch
          checked-children="显示"
          un-checked-children="不显示"
          v-model:checked="record.status"
          @click="handleStatu(record)"
        />
      </template>
      <template #operation="{ record }">
        <a-button
          type="primary"
          size="small"
          @click="handleEdit(record.classify_id)"
          >编辑</a-button
        >
        <a-button size="small" @click="handleDelete(record.classify_id)"
          >删除</a-button
        >
      </template>
    </a-table>
    <a-modal
      centered
      title="添加场景"
      :maskClosable="false"
      cancelText="取消"
      okText="确定"
      class="LiveSceneModel"
      v-model:visible="addSceneVisible"
      :width="700"
      @cancel="formReset"
      @ok="handleOk"
    >
      <a-form ref="formRef">
        <div class="item">
          <a-form-item label="父ID">
            <a-tree-select
              style="width: 220px"
              :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
              placeholder="Please select"
              v-model:value="formState.parent_id"
            >
              <a-tree-select-node
                key="0"
                value="0"
                title="顶部"
              ></a-tree-select-node>
              <a-tree-select-node
                v-for="item in data"
                :key="item.key"
                :value="item.classify_id"
                :title="item.classify_name"
              >
                <template v-if="item.children.length !== 0">
                  <a-tree-select-node
                    v-for="item2 in item.children"
                    :key="item2.key"
                    :value="item2.classify_id"
                    :title="item2.classify_name"
                  >
                  </a-tree-select-node>
                </template>
              </a-tree-select-node>
            </a-tree-select>
          </a-form-item>
          <a-form-item label="名称">
            <a-input
              placeholder="请输入"
              v-model:value="formState.classify_name"
            />
          </a-form-item>
        </div>
        <div class="item">
          <a-form-item label="排序">
            <a-input placeholder="请输入" v-model:value="formState.sort" />
          </a-form-item>
          <a-form-item label="状态">
            <div style="width: 220px">
              <a-switch v-model:checked="formState.checked"></a-switch>
            </div>
          </a-form-item>
        </div>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="visibleDelete"
      centered
      title="删除"
      :maskClosable="false"
      cancelText="取消"
      okText="确定"
      :width="300"
      @ok="handleOkDelete"
    >
      <p>确定删除吗？</p>
    </a-modal>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "@vue/runtime-core";
import { message } from "ant-design-vue";
// import { reactive, toRefs, ref } from "@vue/reactivity";
// import { message } from "ant-design-vue";
// import { onMounted, watch } from "vue";
import {
  getList,
  getEdit,
  getDelete,
  getAdd,
  getEditSave,
} from "../../../service/livescene";
const columns = [
  {
    title: "序号",
    dataIndex: "classify_id",
    width: "122px",
    // key: "id",
  },
  {
    title: "名称",
    dataIndex: "classify_name",
    // key: "name",
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    // key: "action",
  },
  {
    title: "状态",
    dataIndex: "status",
    // key: "status",
    slots: {
      customRender: "menustatu",
    },
  },
  {
    title: "排序",
    dataIndex: "sort",
    // key: "list_order",
  },
  {
    title: "父ID",
    dataIndex: "parent_id",
    // key: "list_order",
  },
  {
    title: "checked",
    dataIndex: "checked",
    // key: "list_order",
  },
  {
    title: "更新时间",
    dataIndex: "update_time",
    // key: "type",
  },
  {
    title: "操作",
    dataIndex: "operation",
    fixed: "right",
    slots: {
      customRender: "operation",
    },
  },
];

export default {
  name: "LiveScene",
  setup() {
    const state = reactive({
      data: [],
      total: undefined,
      page: 1,
      addSceneVisible: false,
      visibleDelete: false,
      deleteId: undefined,
      editId: undefined,
    });
    const formState = reactive({
      classify_id: "",
      classify_name: "",
      sort: "",
      checked: false,
      parent_id: "0",
    });
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await getList({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.data = res.data.data.list;
      state.total = res.data.data.total;
    };
    const addScene = () => {
      state.addSceneVisible = true;
    };
    const handleStatu = (id) => {
      console.log(id);
    };
    const handleEdit = async (id) => {
      //   console.log(id);
      state.editId = id;
      state.addSceneVisible = true;
      const res = await getEdit({
        classify_id: id,
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      let obj = res.data.data;
      for (let key in obj) {
        formState[key] = obj[key];
      }
      //   console.log(formState);
    };
    const handleDelete = (id) => {
      console.log(id);
      state.deleteId = id;
      state.visibleDelete = true;
    };
    const handleOkDelete = async () => {
      const res = await getDelete({
        classify_id: state.deleteId,
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.visibleDelete = false;
      init();
    };
    const formReset = () => {
      formState.classify_id = "";
      formState.classify_name = "";
      formState.sort = "";
      formState.checked = "";
      formState.parent_id = "0";
      state.editId = undefined;
    };
    const handleOk = async () => {
      if (state.editId) {
        //执行编辑操作
        const res = await getEditSave({
          ...formState,
          access_token: sessionStorage.getItem("token"),
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          init();
        } else {
          message.error(res.data.msg);
        }
        state.addSceneVisible = false;
      } else {
        //执行添加操作
        const res = await getAdd({
          ...formState,
          access_token: sessionStorage.getItem("token"),
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          init();
        } else {
          message.error(res.data.msg);
        }
        state.addSceneVisible = false;
      }
      formReset();
    };
    return {
      columns,
      addScene,
      ...toRefs(state),
      handleStatu,
      handleDelete,
      handleEdit,
      handleOkDelete,
      formState,
      formReset,
      handleOk,
    };
  },
};
</script>

<style  scoped>
.LiveScene {
  padding: 15px;
  height: 100%;
  overflow: auto;
}
.topbottom {
  margin-bottom: 15px;
}
.topbottom .ant-btn {
  margin-right: 20px;
}
.ant-table-row .ant-btn-primary:nth-child(1) {
  margin-right: 4px;
}
.item {
  display: flex;
  justify-content: space-between;
}
/* .item  .ant-form-item:nth-child(1){
  margin-right: 50px;
} */
.LiveSceneModel .ant-form {
  margin-right: 10px;
}
.LiveSceneModel .ant-input,
.LiveSceneModel .ant-select {
  width: 220px;
}
</style>
<style>
/* .LiveSceneModel .ant-modal-body {
  padding-bottom: 0;
} */
.LiveSceneModel .ant-form-horizontal .ant-form-item-label {
  min-width: 70px !important;
}
.LiveSceneModel .ant-form-item-control {
  width: 220px;
}
</style>
